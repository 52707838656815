import api from '@/api'
import { AxiosRequestConfig } from 'axios'
import { createStore } from 'vuex'
import { citysList } from '@/types/inerface'
import list from '@/utils/cityList'

export default createStore({
  state: {
    // 城市
    city: {
      city: '请选择',
      id: ''
    },
    coordinate: {
      latitude: '',
      longitude: ''
    },
    cityList: [],
    from: {
      address: '',
      name: '输入起点',
      staCode: ''
    },
    to: {
      address: '',
      name: '输入终点',
      staCode: ''
    },
    lineDetail: {}
  },
  mutations: {
    currentCity (state, val) {
      state.city = val
    },
    cityListInit (state, val) {
      state.cityList = val
    },
    fromInit (state, val) {
      state.from = val
    },
    toInit (state, val) {
      state.to = val
    },
    currentLineDetail (state, val) {
      state.lineDetail = val
    },
    coordinate (state, val) {
      state.coordinate = val
    }
  },
  actions: {
    handleFindCity ({commit, state}) {
      console.log('handleFindCity')
      return api.bus.locationToCity({
        lat: state.coordinate.latitude,
        lng: state.coordinate.longitude
      }).then((res: AxiosRequestConfig['data']) => {
        if (res?.code === 0) {
          console.log('123123456')
          commit('currentCity', {
            city: res.data.city,
            id: res.data.id
          })
        }
      })
    },
    handleGetCityList({commit, state}, city) {
      commit('cityListInit', list)
      state.cityList.forEach((item: citysList) => {
        if (item.city === city) {
          commit('currentCity', item)
        }
      })
    }
  },
  modules: {
  }
})
