import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import qs from 'qs'
import { errHandler, redirectLogin } from './helpers'
import { Toast } from 'vant';

const service = axios.create({
  baseURL: 'https://jf.ditiefuli.com/',
  timeout: 30 * 1000,
  withCredentials: true
})

service.interceptors.request.use((config: AxiosRequestConfig) => {
  // console.log(config)
  // config.headers["X-Requested-With"] = "XMLHttpRequest";
  // config.headers["Request-Start"] = new Date().getTime();
  // const token = 'token';
  // if (token && config.url !== "/auth/oauth/token") {
  //   config.headers["Authorization"] = token;
  // }

  // get请求传递数组
  if (config.method?.toUpperCase() === "GET") {
    config.paramsSerializer = function (params) {
      return qs.stringify(params, { arrayFormat: 'comma' })
    }
  }

  // console.log(config.headers)
  if (Object.values(config.headers).includes("application/x-www-form-urlencoded")) {
    config.data = qs.stringify(config.data);
  }

  return config
},
(error) => {
  // 请求错误
  return Promise.reject(error);
})

service.interceptors.response.use((response: AxiosResponse) => {
  const res = response.data;
  if (res.code && res.code !== 0) {
    // token过期处理
    if (res.code === 401 || res.code === 4001) {
      return redirectLogin()
    }
    // console.log(Promise.reject(service.interceptors.response))
    // return Promise.reject(service.interceptors.response)
    return Toast.fail(response.data.msg)
  } else {
    return res;
  }
},
(error) => {
  errHandler(error)
})

export default service