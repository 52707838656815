import service from '@/http'
import { IObject } from '@/types/inerface'
import { AxiosPromise } from 'axios'

export const register = (data:IObject):AxiosPromise<IObject> => {
  return service({
    url: '/api/user/register',
    data,
    method: 'post'
  })
}

export const login = (data:IObject):AxiosPromise<IObject> => {
  return service({
    url: '/api/user/login',
    data,
    method: 'post'
  })
}

export const loginOut = (data:IObject):AxiosPromise<IObject> => {
  return service({
    url: '/api/user/loginOut',
    data,
    method: 'post'
  })
}
