import router from "@/router";
import { IObject } from '@/types/inerface';
import { Toast } from 'vant';
import { ComponentInstance } from 'vant/lib/utils';
import { NavigationFailure } from 'vue-router';

export const redirectLogin = ():Promise<void | NavigationFailure | undefined> => {
  return router.replace("/login");
};

export const errHandler = (err: IObject | undefined):ComponentInstance => {
  return Toast.fail('请求失败' + err);
};
