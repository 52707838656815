import service from '@/http'
import { IObject } from '@/types/inerface'
import { AxiosPromise } from 'axios'

const key = '29765ba9ffd3c727e107b4f4a885e5bb'
const baseURL = 'http://restapi.amap.com'

// 获取城市列表
export const reverseGeocoding = (latitude: number, longitude: number):AxiosPromise<IObject> => {
  return service({
    baseURL,
    url: `/v3/geocode/regeo?key=${key}&location=${longitude},${latitude}`,
    method: 'GET',
    withCredentials: false
  })
}