import { IObject } from '@/types/inerface'
// import { AxiosPromise } from 'axios'

// interface Api {
//   bus: {
//     cityList: AxiosPromise<IObject>
//   };
// }

const apiList = require.context(
  // 其组件目录的相对路径
  '@/api',
  // 是否查询其子目录
  true,
  // 匹配后缀文件名的文件
  /\.ts$/
)

// console.log(apiList.keys())
const api:IObject = {}
apiList.keys().forEach((item) => {
  // console.log(apiList(item))
  if (item !== './index.ts') {
    const name = item.substring(2, (item.length - 3))
    // console.log(name)
    api[name] = apiList(item)
  }
})

export default api