import service from '@/http'
import { IObject } from '@/types/inerface'
import { AxiosPromise } from 'axios'

export const register = (url:string, data:IObject):AxiosPromise<IObject> => {
  return service({
    url,
    data,
    method: 'post'
  })
}
