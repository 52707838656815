import service from "@/http";
import { IObject } from "@/types/inerface";
import { AxiosPromise } from "axios";

// 获取城市列表
export const cityList = (): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/cityList",
    method: "GET",
  });
};

// 附近站点
export const nearSta = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/nearSta",
    params,
    method: "GET",
  });
};

// 查询公交路线
export const roadLines = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/roadLines",
    params,
    method: "GET",
  });
};

// 公交线路实时信息
export const rtbus = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/rtbus",
    params,
    method: "GET",
  });
};

// 换乘-路线规划
export const transPlan = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/transPlan",
    params,
    method: "GET",
  });
};

// 换乘-地点搜索
export const transSta = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/transSta",
    params,
    method: "GET",
  });
};

// 附近站点-公交查询
export const queryMultiBuses = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/queryMultiBuses",
    params,
    method: "GET",
  });
};

export const locationToCity = (params: IObject): AxiosPromise<IObject> => {
  return service({
    url: "/renren-admin/alipay/bus/locationToCity",
    params,
    method: "GET",
  });
};
