import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Main from "@/views/Main.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Main",
    component: Main,
    children: [
      {
        path: "/",
        name: "Home",
        component: () =>
          import(/* webpackChunkName: "home" */ "@/views/home/Home.vue"),
      },
      {
        path: "/whereToGo",
        name: "WhereToGo",
        component: () =>
          import(
            /* webpackChunkName: "wheretogo" */ "@/views/whereToGo/WhereToGo.vue"
          ),
      },
      {
        path: "/historys",
        name: "Historys",
        component: () =>
          import(
            /* webpackChunkName: "historys" */ "@/views/historys/Historys.vue"
          ),
      },
      {
        path: "/nearBy",
        name: "NearBy",
        component: () =>
          import(/* webpackChunkName: "nearBy" */ "@/views/nearBy/NearBy.vue"),
      },
    ],
  },
  {
    path: "/citys",
    name: "Citys",
    component: () =>
      import(/* webpackChunkName: "citys" */ "@/views/citys/Citys.vue"),
  },
  {
    path: "/busInfo",
    name: "BusInfo",
    component: () =>
      import(/* webpackChunkName: "busInfo" */ "@/views/busInfo/BusInfo.vue"),
  },
  {
    path: "/busList",
    name: "BusList",
    component: () =>
      import(/* webpackChunkName: "busList" */ "@/views/busList/BusList.vue"),
  },
  {
    path: "/locationSearch",
    name: "LocationSearch",
    component: () =>
      import(
        /* webpackChunkName: "locationSearch" */ "@/views/locationSearch/LocationSearch.vue"
      ),
  },
  {
    path: "/transPlan",
    name: "TransPlan",
    component: () =>
      import(
        /* webpackChunkName: "transPlan" */ "@/views/transPlan/TransPlan.vue"
      ),
  },
  {
    path: "/detail",
    name: "Detail",
    component: () =>
      import(
        /* webpackChunkName: "Detail" */ "@/views/transPlan/Detail.vue"
      ),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
