import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant';
import 'vant/lib/index.css';
// import 'normalize.css'
import '@/assets/style/base.less'
// import '@/utils/rem.ts'


createApp(App)
.use(store)
.use(router)
.use(Vant)
.mount('#app')
