<template>
  <div class="app-content">
    <router-view></router-view>
    <van-tabbar v-model="active" class="tabbar" active-color='#ee0a24' route fixed border>
      <van-tabbar-item class="tabbar-item" to="/">
        <span>首页</span>
        <template #icon>
          <i class="iconfont icon-homepage_fill"></i>
        </template>
      </van-tabbar-item>
      <van-tabbar-item to="/whereToGo">
        <span>换乘</span>
        <template #icon>
          <i class="iconfont icon-chaxunluxian"></i>
        </template>
      </van-tabbar-item>
      <!-- <van-tabbar-item to="/nearBy">
        <span>附近</span>
        <template #icon>
          <i class="iconfont icon-lishihuiyi-01"></i>
        </template>
      </van-tabbar-item> -->
      <!-- <van-tabbar-item>
        <span>我的</span>
        <template #icon>
          <i class="iconfont icon-hongbao"></i>
        </template>
      </van-tabbar-item> -->
    </van-tabbar>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const active = ref(0)
    return {
      active
    }
  },
})
</script>


<style lang="less" scoped>
.app-content {
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}
.tabbar {
  border-top: 1px solid #ddd;
  height: 100px;
  /deep/ .iconfont {
    font-size: 36px;
  }
  /deep/ .van-tabbar-item__text {
    font-size: 24px;
  }
}
</style>
