
import { defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'Home',
  components: {
  },
  setup() {
    const active = ref(0)
    return {
      active
    }
  },
})
